.list-status-filter {
  display: flex;

  .text-col {
    max-width: 110px;
    text-align: right;
    padding-right: 1rem;
    margin-top: 5px;
  }

  .filter-col {
    .filter-utility {
      position: relative;
      display: inline-flex;
      margin-left: 15px;
      top: -4px;
    }
  }
}

.staff-name {
  font-weight: 400;
  color: black;
  text-shadow: -0.03rem -0.03rem 0 #000, 0.03rem -0.03rem 0 #000, -0.03rem 0.03rem 0 #000, 0.03rem 0.03rem 0 #000;
}

@keyframes rainbow-bg{
  100%,0%{
    background-color: rgb(255,0,0);
  }
  8%{
    background-color: rgb(255,127,0);
  }
  16%{
    background-color: rgb(255,255,0);
  }
  25%{
    background-color: rgb(127,255,0);
  }
  33%{
    background-color: rgb(0,255,0);
  }
  41%{
    background-color: rgb(0,255,127);
  }
  50%{
    background-color: rgb(0,255,255);
  }
  58%{
    background-color: rgb(0,127,255);
  }
  66%{
    background-color: rgb(0,0,255);
  }
  75%{
    background-color: rgb(127,0,255);
  }
  83%{
    background-color: rgb(255,0,255);
  }
  91%{
    background-color: rgb(255,0,127);
  }
}

@keyframes rainbow{
  100%,0%{
    color: rgb(255,0,0);
  }
  8%{
    color: rgb(255,127,0);
  }
  16%{
    color: rgb(255,255,0);
  }
  25%{
    color: rgb(127,255,0);
  }
  33%{
    color: rgb(0,255,0);
  }
  41%{
    color: rgb(0,255,127);
  }
  50%{
    color: rgb(0,255,255);
  }
  58%{
    color: rgb(0,127,255);
  }
  66%{
    color: rgb(0,0,255);
  }
  75%{
    color: rgb(127,0,255);
  }
  83%{
    color: rgb(255,0,255);
  }
  91%{
    color: rgb(255,0,127);
  }
}

.approve-new-page {
  .approve-new-form {
    .notice-textarea {
      height: 100%;
      resize: none;
    }

    .term-check {
      .form-label {
        margin-bottom: 0;
      }

      margin-bottom: 0.5rem;
    }

    .red-label {
      color: #f46a6a;
    }
  }
}
